<div *ngIf="type==0" [style.background-color]="item.customField15" style="border-radius: 12px;">
    <div class="input-row backg " [formGroup]="firstFormGroup" autocomplete="off">
        <div fxLayout="column">
            <div class="result padding-10" fxLayout="column">
                <div class="input-row1 padding-r20 padding-b10" fxLayout="row" fxflex="100" fxLayoutGap="10px">
                    <h1 [style.font-size]="item.customField11" [style.text-align]="item.customField12">
                        <calsoft-editor-text [data]="data" [text]="input" [align]="item.customField34">
                        </calsoft-editor-text></h1>
                </div>
                <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px grid">
                    <mat-form-field fxFlex.gt-md="50" fxFlex.lt-md appearance="outline">
                        <mat-label>Name</mat-label>
                        <input [autocomplete]="'off'" trim="blur" matInput formControlName="name" required>
                        <mat-error *ngIf="firstFormGroup.controls.name.errors?.required">
                            Please fill out this field.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex.gt-md="50" fxFlex.lt-md appearance="outline">
                        <mat-label>Telephone</mat-label>
                        <input [autocomplete]="'off'" trim="blur" matInput formControlName="telephone" required>
                        <mat-error *ngIf="firstFormGroup.controls.telephone.errors?.required">
                            Please fill out this field.
                        </mat-error>
                    </mat-form-field>

                </div>
                <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px grid">
                    <mat-form-field fxFlex.gt-md="50" fxFlex.lt-md appearance="outline">
                        <mat-label>Subject</mat-label>
                        <textarea trim="blur" matInput formControlName="subject" required></textarea>
                        <mat-error *ngIf="firstFormGroup.controls.subject.errors?.required">
                            Please fill out this field.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex.gt-md="50" fxFlex.lt-md appearance="outline">
                        <mat-label>Email Id</mat-label>
                        <input [autocomplete]="'off'" type="email" trim="blur" matInput formControlName="emailId"
                            required>
                        <mat-error *ngIf="firstFormGroup.controls.emailId.errors?.required">
                            Please fill out this field.
                        </mat-error>
                        <mat-error *ngIf="firstFormGroup.controls.emailId.errors?.pattern">
                            Enter a valid Email Format.
                        </mat-error>
                    </mat-form-field>

                </div>

                <div class="input-row" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px grid">
                    <mat-form-field fxFlex appearance="outline">
                        <mat-label>Message</mat-label>
                        <textarea trim="blur" matInput formControlName="message" required></textarea>
                        <mat-error *ngIf="firstFormGroup.controls.message.errors?.required">
                            Please fill out this field.
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>
        </div>

        <div class="input-row1 padding-b10" fxLayout="row" fxflex="100" fxLayoutGap="10px">
            <button class="primary" style="color:white" fxFlex mat-flat-button [disabled]="text !='Submit'"
                (click)="createContact()">
                {{text}}
                <mat-icon *ngIf="text !='Submit' ">
                    <mat-spinner class="primary" diameter="20">
                    </mat-spinner>
                </mat-icon>
            </button>

        </div>
    </div>
</div>
<div *ngIf="type==1" [style.background-color]="item.customField15" style="border-radius: 12px;">
    <div class="input-row backg" autocomplete="off">
        <div fxLayout="column">
            <div class="result padding-10" fxLayout="column">
                <div class="input-row1 padding-r20 padding-b10" fxLayout="row" [fxLayoutAlign]="item.customField12" fxflex="100" fxLayoutGap="10px">
                    <h1 [style.font-size]="item.customField11" >
                        <calsoft-editor-text [data]="data" [text]="input" [align]="item.customField34">
                        </calsoft-editor-text>
                    </h1>
                </div>


                <div *ngIf="init">
                    <dynamic-form  [layout]="alignMent" [fields]="regConfig" (submit)="submit($event)">
                    </dynamic-form>
                </div>
            </div>
        </div>

    </div>

</div>