<div *ngIf="align>0 ; else noAlign" >
    <div [fxFlex]="align">
    
    
    </div>
    <div [ngStyle]="{'font-family': data?.websiteFontFamily}" [innerHTML]="trustedHtmlContent"></div> 
    
    <div [fxFlex]="align">
    
    
    </div>
    </div>

    <ng-template #noAlign>

        
    <div [ngStyle]="{'font-family': data?.websiteFontFamily}" [innerHTML]="trustedHtmlContent"></div>

   </ng-template>

