import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { AnalyticService } from 'src/app/services/analytic-service';
import { CommonService } from 'src/app/services/common.service';
import { ContactService } from 'src/app/services/contact.service';
import { PixelService } from 'src/app/services/pixel.service';
import { SupplierRegistrationService } from 'src/app/services/supplier-registration.service';
import { FieldConfig } from 'src/app/shared/component/field.interface';

@Component({
  selector: 'calsoft-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  text: string;
  firstFormGroup: UntypedFormGroup;
  @Input() input: any;
  @Input() data: any;
  @Input() item: any;

  type: any;

  customField31: any;

  regConfig: any[] = [
    // {
    //   type: "input",
    //   label: "Usernames",
    //   inputType: "text",
    //   name: "name",
    //   validations: [
    //     {
    //       name: "required",
    //       validator: Validators.required,
    //       message: "Name Required"
    //     },
    //     {
    //       name: "pattern",
    //       validator: Validators.pattern("^[a-zA-Z]+$"),
    //       message: "Accept only text"
    //     }
    //   ]
    // },
    // {
    //   type: "input",
    //   label: "Email Address",
    //   inputType: "email",
    //   name: "email",
    //   validations: [
    //     {
    //       name: "required",
    //       validator: Validators.required,
    //       message: "Email Required"
    //     },
    //     {
    //       name: "pattern",
    //       validator: Validators.pattern(
    //         "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
    //       ),
    //       message: "Invalid email"
    //     }
    //   ]
    // },
    // {
    //   type: "input",
    //   label: "Password",
    //   inputType: "password",
    //   name: "password",
    //   validations: [
    //     {
    //       name: "required",
    //       validator: Validators.required,
    //       message: "Password Required"
    //     }
    //   ]
    // },
    // {
    //   type: "radiobutton",
    //   label: "Gender",
    //   name: "gender",
    //   options: ["Male", "Female"],
    //   value: "Male"
    // },
    // {
    //   type: "date",
    //   label: "DOB",
    //   name: "dob",
    //   validations: [
    //     {
    //       name: "required",
    //       validator: Validators.required,
    //       message: "Date of Birth Required"
    //     }
    //   ]
    // },
    // {
    //   type: "select",
    //   label: "Country",
    //   name: "country",
    //   value: "UK",
    //   options: ["India", "UAE", "UK", "US"]
    // },
    // {
    //   type: "checkbox",
    //   label: "Accept Terms",
    //   name: "term",
    //   value: true
    // },
    // {
    //   type: "button",
    //   label: "Save"
    // }
  ];
  init: boolean = false;

  constructor(private _formBuilder: UntypedFormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    public googleAnalyticsService: AnalyticService,
    private contactService: ContactService,
    private pixelService:PixelService,
    private supplierRegistrationService: SupplierRegistrationService,) { }

    alignMent:any=2;

  ngOnInit(): void {



    if(window.innerWidth> 600){

      this.alignMent=this.item.customField26

    }else{
      this.alignMent=this.item.customField27
    }


    if (this.item['customField30'] != undefined) {
      this.type = parseInt(this.item['customField30'])


    } else {
      this.type = 0;

    }


    if (this.item.customField31 != undefined) {
      this.customField31 = this.item.customField31;
    }


    if (this.type == 0) {
      this.createContactForm();
    } else {
      this.createDynamicForm(this.item['value']);
    }

    this.text = 'Submit';
  }

  createContactForm() {
    this.firstFormGroup = this._formBuilder.group({
      id: [''],
      name: ['', [Validators.required]],
      telephone: ['', [Validators.required]],
      emailId: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
      isActive: new UntypedFormControl(0)
    });
  }


  createDynamicForm(formGroupId) {
    this.contactService.getDynmaicForm(formGroupId).subscribe((data) => {

      let value: any = data
      this.regConfig = value;
      console.log(this.regConfig);
      this.init = true;
    });
  }

  createContact() {

    this.firstFormGroup.value.operationType = "New"

    if (this.firstFormGroup.valid && this.text == 'Submit') {
      this.text = "Processing"
      let uniqueId:any=this.generateUniqueId();
      this.setConversionTrackingId(this.data['coversionTrackingIdGa'],1,uniqueId)
      this.setFacebookConverison(uniqueId,1);
      this.contactService.createContact(this.firstFormGroup.value).subscribe(
        (response) => {
          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
            this.text = "Submit";
            this.firstFormGroup.reset();
          } else {
            this.text = "Submit";

            this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        })
    } else {
      this.firstFormGroup.markAllAsTouched();
    }

  }

  setConversionTrackingId(conversionId,grandTotal,extOrderId){
    if(conversionId!=null){
      let value:any= localStorage.getItem('currency');
      this.googleAnalyticsService.event('purchase',grandTotal);
      this.googleAnalyticsService.eventEmitter 
      ('conversion',conversionId,
      grandTotal,
      value,extOrderId);
    }
  }
  setFacebookConverison(uniqueId,grandTotal){

  
    let value:any= localStorage.getItem('currency');
    this.pixelService.track('Purchase', {
      content_ids: uniqueId,  
      value: grandTotal,                    
      currency: value                 
    });
    this.pixelService.track('Lead', {
      content_ids:uniqueId, 
      value: grandTotal,                        
      currency: value                  
    });
  }

  generateUniqueId(): string {
    // Generate a unique ID using Renderer2
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  }

  submit(value: any) {

    let filteredList = this.regConfig
      .filter(item => item.inputType.toLowerCase().includes('button'))
      .map(item => ({ label: item.label }));




    filteredList.forEach(label => {
      if (value.hasOwnProperty(label.label)) {
        delete value[label.label];
      }
    });

    let filteredListFile = this.regConfig
      .filter(item => item.inputType.toLowerCase().includes('file'))
      .map(item => ({ label: item.name }));

    filteredListFile.forEach(label => {
      if (value.hasOwnProperty(label.label)) {
        const newKey = label.label + '_file';
        value[newKey] = value[label.label];
        delete value[label.label];
      }
    });

    let object = {};
    let keysToCheck = ["phoneNumber", "email", "name"];
    let keysList = keysToCheck.filter(key => value.hasOwnProperty(key));
    keysList.forEach(key => {
      object[key] = value[key];
    });

    object['formGroupId'] = this.item['value'];
    object['value'] = JSON.stringify(value);


    if (this.customField31 == 1) {

      this.saveForm(object)

    } else {

      this.SubmitVendor(object);
    }
  }

  saveForm(object) {
    if (this.text == 'Submit') {
      this.contactService.saveForm(object).subscribe(
        (response) => {
          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            this.text = "Submit";
            this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
            this.router.navigate([''])
          }
          else {
            this.text = "Submit";
            this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        },
        (err) => { this.text = "Submit" });
    }
    else {

    }
  }


  SubmitVendor(object) {

    if (this.text == 'Submit') {

      if (object.address == null) {
        object.address = 0;
      }

      if (object.gstNo == null) {
        object.gstNo = 0;
      }

      if (object.phoneNo == null) {
        object.phoneNo = 0;
      }

      if (object.pincode == null) {
        object.pincode = 0;
      }

      if (object.state == null) {
        object.state = 0;
      }

      if (object.city == null) {
        object.city = 0;
      }

      if (object.accountNo == null) {
        object.accountNo = 0;
      }

      if (object.confirmAccount == null) {
        object.confirmAccount = 0;
      }

      if (object.ifscCode == null) {
        object.ifscCode = 0;
      }

      if (object.storeName == null) {
        object.storeName = 0;
      }

      if (object.country == null) {
        object.country = 'India';
      }

      if (object.email == null) {
        object.email = 0;
      }

      if (object.password == null) {
        object.password = 0;
      }

      if(object.operationType = "New"){
        this.supplierRegistrationService.submitSupplier(object).subscribe(
          (response) => {
            if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
              this.text = "Submit";
              this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
              this.router.navigate([''])
            }
            else {
              this.text = "Submit";
              this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
            }
          },
          (err) => { this.text = "Submit" });
      }
      else {
  
      }
      }


  }


}
