import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class NewlogInService {

  constructor(private http: HttpClient) { }

  url = environment.serverAPI4 + "/api/newLogIn";

  getNewLogIn(): Observable<Object> {
    return this.http.get(`${this.url}`);
  }

  savenewLogIn(user: User): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url}`, user);
  }

  updateNewLogIn(user: User): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url}`, user);
  }

}
