<span  (click)="close()" style="padding: 0px ; HEIGHT: 0PX;margin: 0px;" mat-dialog-title>
    <span (click)="close()" fxLayout="row" fxLayoutAlign="end center">
      <button (click)="close()" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </span>
</span>
  
  
  <div class="popup">
    <mat-dialog-content style=" margin: 0px;  max-height: 100%;
    padding: 0px;">
  
      <div fxFlex="3">
  
      </div>
  
      <div fxLayout="column" fxFlex="40" fxFlex.xs="100" fxLayoutAlign="center center">
  
    
  
  
        <div class="title" fxLayout="column">
          <h2 class="form-title">{{popupText}}</h2>
        </div>

  
  
        <div class="form">
            <div *ngIf="init">
                <dynamic-form [fields]="regConfig" (submit)="submit($event)">
                </dynamic-form>
            </div>
        </div>
        
        
  
      </div>
  
    </mat-dialog-content>
  </div>
  