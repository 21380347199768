import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'calsoft-dynamic-form-popup',
  templateUrl: './dynamic-form-popup.component.html',
  styleUrls: ['./dynamic-form-popup.component.scss']
})
export class DynamicFormPopupComponent {


  text: string;
  firstFormGroup: UntypedFormGroup;
  @Input() data: any;
  popupForm: any;
  popupText: any;

  @Input() input: any;
  @Input() item: any;

  regConfig: any[] = [];

  constructor(
    private configService: ConfigServiceService,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
    private contactService: ContactService,
    private router: Router,

    ) {}

    

    init: boolean = false;
    
  ngOnInit() {

    this.getConfigData();

    this.createDynamicForm(this.item['value']);

  }


  createDynamicForm(formGroupId) {
    this.contactService.getDynmaicForm(formGroupId).subscribe((data) => {

      let value: any = data
      this.regConfig = value;
      console.log(this.regConfig);
      this.init = true;
    });
  }

  close(){
    this.dialog.closeAll();
  }


  getConfigData() {
    this.configService.getConfigData()
      .subscribe(
        data => {
  
          this.popupForm=data['popupForm'];
  
          this.popupText=data['popupText'];
          
  
        }, error => {
  
        }
      );
  }


  submit(value: any) {

    let filteredList = this.regConfig
      .filter(item => item.inputType.toLowerCase().includes('button'))
      .map(item => ({ label: item.label }));




    filteredList.forEach(label => {
      if (value.hasOwnProperty(label.label)) {
        delete value[label.label];
      }
    });

    let filteredListFile = this.regConfig
      .filter(item => item.inputType.toLowerCase().includes('file'))
      .map(item => ({ label: item.name }));

    filteredListFile.forEach(label => {
      if (value.hasOwnProperty(label.label)) {
        const newKey = label.label + '_file';
        value[newKey] = value[label.label];
        delete value[label.label];
      }
    });

    let object = {};
    let keysToCheck = ["phoneNumber", "email", "name"];
    let keysList = keysToCheck.filter(key => value.hasOwnProperty(key));
    keysList.forEach(key => {
      object[key] = value[key];
    });

    object['formGroupId'] = this.item['value'];
    object['value'] = JSON.stringify(value);



      this.saveForm(object)

    }


    saveForm(object) {
      if (this.text == 'Submit') {
        this.contactService.saveForm(object).subscribe(
          (response) => {
            if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
              this.text = "Submit";
              this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
              this.router.navigate([''])
            }
            else {
              this.text = "Submit";
              this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
            }
          },
          (err) => { this.text = "Submit" });
      }
      else {
  
      }
    }


}
