<mat-toolbar class="primary" style="z-index: 1000;" >
  <mat-toolbar-row style="height: 100%;" fxLayout="row" fxLayoutAlign="space-between center">
      <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">

      <calsoft-logo  [sitename]="data.siteName" fxLayoutAlign="start start" fxFlex [component]="'menu'"
        (click)="closeMenu()" [type]="'light'"></calsoft-logo>
     </section>

     <span  *ngIf="displayNameFully" fxLayoutAlign="center center" fxFlex="50" (click)="account1()" 
     style="color: white;">
     Hi {{username}}
        
   </span>
   

      <section   (click)="closeMenu()" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="2px">
        <button  fxFlex="20" fxLayoutAlign="end end" (click)="closeMenu()" mat-icon-button>
          <mat-icon style="color:white">close</mat-icon>
        </button>
        

      </section>

  </mat-toolbar-row>
 

</mat-toolbar>
<div  *ngFor="let menu of sideMenuItem;let i = index">

  <div *ngIf="menu.displayMode==6; else notButton">
<!-- 			
    <calsoft-button-category [menuData]="menu"></calsoft-button-category> -->
</div>

<ng-template #notButton>
  <ng-container *ngIf="menu.subcategories.length > 0">

    <a [ngStyle]="{'margin-top':i==0 ? '10px' : '0px' }" mat-list-item (click)="categories(menu,i)">
      <img onerror="this.src='assets/images/products/noImage/no-image.png';" style="height: 25px;width: 25px;" [src]="serverPath+menu.image" [alt]="menu.categoryName">
      &nbsp;{{menu.categoryName | uppercase}}
    </a>

  </ng-container>

  <ng-container *ngIf="menu.subcategories.length== 0">

    <a  [ngStyle]="{'margin-top':i==0 ? '10px' : '0px' }" mat-list-item (click)="goToCategory(menu)">
      <img  onerror="this.src='assets/images/products/noImage/no-image.png';"style="height: 25px;width: 25px;" [src]="serverPath+menu.image" [alt]="menu.categoryName">
      &nbsp;{{menu.categoryName | uppercase}}
    </a>
  </ng-container>
  </ng-template>

</div>
<mat-divider></mat-divider>
<div>
  <a mat-list-item *ngIf="data.disableCartIconInHeader==0" (click)="account('/cart')">
    <mat-icon class="primary-icon">add_shopping_cart</mat-icon>
    My Cart
  </a>
  <a mat-list-item *ngIf="data.disableYourOrders==0" (click)="account('/orders')">
    <mat-icon class="primary-icon">text_snippet</mat-icon>
    My Orders
  </a>
  <a mat-list-item  *ngIf="data.disableWishlistHeader==0" (click)="account('/account/wishlist')">
    <mat-icon class="primary-icon">favorite</mat-icon>
    My Wishlist
  </a>
  <a mat-list-item *ngIf="data.disableLoginHeader==0"  (click)="account('/account/info')">
    <mat-icon class="primary-icon">person</mat-icon>
    My Account
  </a>
  <a mat-list-item *ngIf="data.disableCoupoun==1" (click)="account('/coupon')">
    <mat-icon class="primary-icon">local_offer</mat-icon>
    Coupon
  </a>
  <a mat-list-item *ngIf="data.showContactPage==1" (click)="account('/contact-us')">
    <mat-icon class="primary-icon">contact_page</mat-icon>
    Contact-Us
  </a>
  <a mat-list-item (click)="account('/aboutus')">
    <mat-icon class="primary-icon">info</mat-icon>
    About-Us
  </a>
  <a mat-list-item  *ngIf="data.showBlogPage==1" (click)="account('/blog')">
    <mat-icon class="primary-icon">chat</mat-icon>
    Blog
  </a>
  <a mat-list-item *ngIf="data.showBrochurePage==1" (click)="account('/brochure')">
    <mat-icon class="primary-icon">menu_book</mat-icon>
    Brochure
  </a>
</div>

<div  *ngIf="data.enablePolicyInMob==1">
  <calsoft-menu-iteration [data]="data" class="padding-5"></calsoft-menu-iteration>
</div>



<calsoft-menu-iteration2 *ngIf="referer.includes('vgrowfarms.')" class="padding-5"></calsoft-menu-iteration2>
<!-- <button (click)="addToHomeScreen1()">Add to Home Screen</button> -->




<div *ngIf="basicAuthenticationService.userLogin">
  <div (click)="signOut()">
    <a mat-list-item>
      <mat-icon class="primary-icon">logout</mat-icon>
      Logout
    </a>
  </div>
</div>
